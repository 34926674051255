<template>
  <div class="support">
    <div class="left">
      <img
        :src="require('@/assets/images/login-logo.png')"
        alt=""
        class="logo"
      />
    </div>
    <div class="right">
      <div class="panel-bg">
        <div class="panel">
          <img :src="require('@/assets/images/qrcode.jpeg')" alt="" class="qrcode">
          <div class="phone-wrap">
            <p class="phone-title">
              <span>咨询热线</span>
            </p>
            <p class="phone">020-34248987</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    document.title = '支持'
  },
})
</script>

<style lang="scss" scoped>
@import "support";
</style>
